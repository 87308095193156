@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.card-media {
  display: flex;
  flex-wrap: wrap;
  min-height: 75vh;

  .col-wrapper--content {
    text-align: center;
    width: 100%;

    @include breakpoint-up(md) {
      text-align: left;
    }
  }

  &.has-media-in-right-col {
    @include breakpoint-up(md) {
      :nth-child(1) {
        order: 2;
      }

      :nth-child(1) {
        order: 1;
      }
    }
  }

  .col-wrapper--media {
    padding-right: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .form-container {
      display: flex;
      flex-direction: column;
      // justify-content: center;
      // align-items: center;
      padding: 24px 16px;
      gap: 16px;
      width: auto;
      @include breakpoint-up(md) {
        min-width: 340px;
      }
      h4 {
        text-align: center;
        @include breakpoint-up(md) {
          text-align: left;
        }
      }
      background: rgba(0, 0, 0, 0.5);
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
      border-radius: 16px;
    }
  }

  .col-wrapper--content {
    padding-right: 0;
    margin-bottom: var(--spacing-1);
    @include breakpoint-up(md) {
      margin-bottom: var(--footer-margin-top);
    }
    @include breakpoint-up(md) {
      margin-bottom: 0px;
    }
    h2,
    p {
      margin-top: 0;
    }

    .subTitle {
      color: var(--gray-300);
      font-size: var(--font-size-28);
    }

    .img-subheadline {
      margin-top: -10px;
      margin-bottom: 5px;
    }

    p {
      @include body-copy(md);

      &:last-child {
        margin-bottom: 0;
      }
    }

    ul {
      margin-top: 0;
      margin-bottom: var(--spacing-2);
      padding-left: var(--spacing-2);

      li {
        margin-bottom: 3px;
      }
    }

    button {
      margin-top: var(--spacing-1);
      margin-right: auto;
      margin-left: auto;

      @include breakpoint-up(md) {
        margin-right: 0;
        margin-left: 0;
      }
    }
  }
}

@include breakpoint-down(sm) {
  .media-column {
    margin-bottom: var(--spacing-1);
  }
}
