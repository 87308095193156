@import '../../../styles/mixins.scss';
@import '../../../styles/variables.breakpoints.module.scss';

.btn-lg-wrapper {
  width: 100%;
  margin: var(--spacing-1) auto;

  &.btn-lg-wrapper--justify-contents-center {
    text-align: center;
  }

  &.btn-lg-wrapper--justify-contents-left {
    text-align: left;
  }
}

.btn {
  @include btn();

  + .btn {
    margin-top: calc(var(--spacing-1) / 2);
  }

  &.btn--xs {
    @include btnSize(xs);
  }

  &.btn--sm {
    @include btnSize(sm);
  }

  &.btn--md {
    @include btnSize(sm);

    @include breakpoint-up(sm) {
      @include btnSize(md);
    }
  }

  &.btn--lg {
    @include btnSize(lg);
  }

  &.btn--blue {
    @include btnBgColor(blue);
  }

  &.btn--white {
    @include btnBgColor(white);
  }

  &.btn--black {
    @include btnBgColor(black);
  }

  &.btn--transparent {
    @include btnBgColor(transparent);

    &.btn--is-disabled {
      @include btnBgColor(transparent, true);
    }
  }

  &.btn--black,
  &.btn--blue,
  &.btn--white {
    &.btn--is-disabled {
      @include btnBgColor(gray-500, true);
    }
  }

  &.btn--is-full-width {
    display: block;
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }

  &.btn--justify-center {
    margin-right: auto;
    margin-left: auto;
    text-align: center;
  }

  &.btn--justify-right {
    margin-left: auto;
  }
}
