.image-link {
  margin: 25px;
}

.pop-in {
  opacity: 0;
  transition: 1s ease 0.5s;
  transition-delay: 0.5s;
  transform: scale(0.5);
}

.has-popped-in {
  transform: scale(1);
  opacity: 1;
}
