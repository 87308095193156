@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.header-bg-gradient {
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-index-101);
  height: calc(var(--header-height-mobile) + 15px);
  width: 100%;
  background: var(--header-bg-color);

  @include breakpoint-up(lg) {
    height: calc(var(--header-height-desktop) + 20px);
  }
}

.header-global {
  // position: fixed;
  left: 0;
  top: 0;
  z-index: 9000;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 var(--spacing-1);
  overflow: visible;
  background-color: transparent;
  transition: background-color 0.2s ease;
  justify-content: space-between;

  .header-global__wrapper {
    display: flex;
    justify-content: space-between;
  }

  li {
    text-transform: uppercase;
    list-style: none;

    a,
    span {
      font-weight: var(--font-weight-light);
    }
  }

  .brand-logo {
    display: flex;
    align-items: center;
    width: var(--header-brand-mobile-width);
    min-width: var(--header-brand-mobile-width);
    height: var(--header-brand-mobile-height);
    min-height: var(--header-brand-mobile-height);
    margin-right: 25px;

    @include breakpoint-up(md) {
      width: var(--header-brand-logo-width);
      min-width: var(--header-brand-logo-width);
      height: var(--header-brand-logo-height);
      min-height: var(--header-brand-logo-height);
    }

    a {
      display: flex;
    }
  }

  &.header-global--desktop {
    height: var(--header-height-desktop);
    padding-top: 0;

    .header-global__column {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
    }

    .nav-container {
      display: flex;
      margin-left: auto;

      .ctas {
        display: flex;
        margin-left: 10px;

        .cta:nth-of-type(2) {
          margin-left: 25px;
        }
      }
    }

    nav {
      color: var(--white);
      transition: height 0.1s ease-out;

      > ul {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding-left: 0;
        padding-bottom: 0;
        margin: 0;
        text-align: center;

        li {
          display: flex;
          align-items: center;
          height: 100%;
          padding: 0 22.5px;
          font-weight: var(--font-weight-light);
          border: none;
          position: static;

          &:first-child {
            padding-left: 0;
          }

          ul.submenu {
            position: fixed;
            left: 0;
            top: var(--header-height-desktop);
            bottom: calc(var(--headerSubmenuHeightDesktop) * -1);
            display: flex;
            align-items: center;
            justify-content: center;
            height: var(--header-submenu-height-desktop);
            width: 100%;
            padding: 0;
            margin-bottom: 8px;
            border-top: 1px solid var(--gray-900);
            border-bottom: 1px solid var(--gray-900);
            background: var(--header-subnav-bg-color);
            backdrop-filter: blur(25px);

            li {
              align-items: center;
              border-bottom: 3px solid transparent;

              a {
                text-transform: none;

                &:hover {
                  color: var(--white);
                }
              }

              &.is-current-page {
                border-color: var(--gold-500);

                a {
                  color: var(--white);
                }
              }
            }
          }

          .menu-item-wrapper {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: 0 var(--spacing-1);
            cursor: pointer;
            display: block;
            padding: 0;
            white-space: nowrap;

            .is-active-link {
              font-weight: var(--font-weight-semi-bold);

              &:hover {
                color: var(--white);
              }
            }

            .caret {
              position: absolute;
              left: 50%;
              top: 33px;
              transform: translate(-50%, -50%);

              svg {
                opacity: 50%;
                height: 10px;
                width: 10px;
              }
            }

            a,
            span {
              @include link(gray-100);
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  &.header-global--mobile {
    height: var(--header-height-mobile);

    .header-global__column {
      display: flex;

      .drawer-toggler-logo {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .cta {
        margin-left: 25px;
      }
    }

    .nav-drawer__toggler svg {
      @include svgBtn(white);
      margin-right: 25px;
    }

    .brand-logo {
      margin-right: auto;
      /* 
       * targetVersion: v2.0.0
       */
      // margin-left: auto;
    }

    &.drawer-open {
      width: 100%;
      height: 100%;
      backdrop-filter: blur(12.5px);
      background: rgb(27, 27, 27, 0.75); //store in global variable

      // hide due to position shift
      .header-global__column,
      .header-global__column + .brand-logo {
        display: none;
      }
    }

    nav {
      position: absolute;
      top: 0;
      left: -320px;
      height: 100vh;
      width: 320px;
      z-index: 1000;
      background-color: var(--black);
      transition: height 0.1s ease-out;
      letter-spacing: 0.02em;
      max-width: 100%;
      transition: all 0.1s;

      &.is-open {
        left: 0;
      }

      .nav-drawer__scroller {
        height: calc(100% - 60px);
        padding-bottom: 120px;
        overflow: auto;

        ul {
          margin-bottom: 40px;

          li {
            font-size: var(--font-size-24);
          }
        }
      }

      .nav-drawer__header {
        display: flex;
        align-items: center;
        height: var(--header-height-mobile);
        padding: 0 var(--spacing-1);

        .nav-drawer__toggler {
          display: flex;

          svg {
            @include svgBtn(white);
          }
        }

        .nav-drawer__brand-logo {
          margin-left: 24px;
        }
      }

      .nav-drawer__ctas {
        padding-right: 15px;
        padding-left: 15px;
        text-align: center;

        .cta + .cta {
          margin-top: 15px;
        }
      }

      ul {
        width: 100%;
        padding: 0;
        margin: 0;

        a {
          @include link(white);
        }

        li {
          padding: 23px 0;
          border-bottom: 1px solid var(--gray-500);

          &:last-child {
            border: none;
          }

          &.is-open {
            .caret {
              transform: rotate(180deg);
            }
          }

          .menu-item-wrapper {
            position: relative;
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 0 var(--spacing-1);
            cursor: pointer;

            .caret svg {
              width: 13.5px;
            }
          }

          .submenu {
            padding: var(--spacing-1) var(--spacing-1) 0 var(--spacing-3);

            li {
              padding: 10px 0;
              border: none;
              text-transform: none;

              &:last-child {
                padding-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
}
