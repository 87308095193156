@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.footer-global {
  padding: 16px;
  gap: 24px;
  border-top: 1px solid var(--gray-500);
  margin-top: var(--footer-margin-top);

  @include breakpoint-up(md) {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
  }
  .footer-bottom {
    justify-content: center;
    @include breakpoint-up(md) {
      display: flex;
      justify-content: flex-start;
    }
    .footer-bottom-logos {
      @include breakpoint-up(md) {
        display: flex;
        justify-content: flex-start;
        gap: 8%;
      }
      .logo {
        margin: auto;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: var(--footer-sponsor-logo-width);
        min-width: var(--footer-sponsor-logo-width);
        height: var(--footer-sponsor-logo-height);
        min-height: var(--footer-sponsor-logo-height);
        margin-bottom: 16px;
        @include breakpoint-up(md) {
          display: none;
        }
      }
    }
    .social-footer {
      width: 100%;
      justify-content: center;
      display: grid;
      @include breakpoint-up(md) {
        display: flex;
        justify-content: space-between;
      }
      ul {
        display: flex;
        padding: 0px;
        margin: 0px;
        justify-content: center;
        margin-bottom: 16px;
        @include breakpoint-up(md) {
          justify-content: left;
          margin: 0px;
        }
        li {
          list-style: none;
          padding-right: 8px;
        }
      }
      a {
        color: var(--gray-500);
        transition: var(--link-color-transition);
        cursor: pointer;
        text-decoration: none;
        font-size: var(--font-size-12);
      }
    }
  }
}
