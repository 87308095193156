@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.h1 {
  @include heading(1);
}

.h2 {
  @include heading(2);
}

.h3 {
  @include heading(3);

  a {
    @include link(white);
  }
}

.h4 {
  @include heading(4);

  a {
    @include link(white);
  }
}

.h5 {
  @include heading(5);
}

.h6 {
  @include heading(6);
}

.eyebrow {
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-semi-bold);
  letter-spacing: 0.105em;
  text-transform: uppercase;
  color: var(--gold-500);
}

.to-uppercase {
  text-transform: uppercase;
}

.heading {
  &.justify-center {
    text-align: center;
  }

  &.justify-left {
    text-align: left;
  }

  @each $margin-level, $margin-value in $margins_headings {
    &.margin-top-#{$margin-level} {
      margin-top: $margin-value;
    }

    &.margin-bottom-#{$margin-level} {
      margin-bottom: $margin-value;
    }
  }

  $font-sizes: (140, 80, 64, 56, 48, 30, 26, 20, 16, 14, 12);

  @each $font-size in $font-sizes {
    &.font-size-#{$font-size} {
      font-size: var(--font-size-#{$font-size});
    }
  }
}
